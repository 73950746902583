.container {
    margin:10rem 0 0 0;
    text-align: center;
    align-items: center;
}

.titleText
{
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3rem;
}


.infoText
{
    margin-top: 0;
    padding-top: 0;
    font-size: 2rem;
    line-height: 3rem;
}
