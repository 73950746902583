.container {
    margin:0;
    padding:2rem;
    text-align: center;
    /* //background-color:  #f9fe95; */
    /* background-color: #FEFFC2; */
    background-color: #ffffff;
   

}
.infoText {
    font-size: 1.5rem;
    color:#23518D;
}
.linkText {
    font-size: 1.5rem;
    color:#23518D;
}
.links{
    text-decoration: none;
}

.linkContainer{
    margin:0 8rem 0 8rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    
}

.logo {
    margin: 1rem 0;
    width:10%;
}



/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
   

    .container {
     
       padding:2rem;
    }
  
    .logo{
        width:20%;
    }
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 65em) {

    .linkContainer{
        flex:1;
        flex-direction: column;
        margin: 0 2rem;
        gap: 1rem;
    }

.logo{
    width:30%;
}
}