
* {
  margin: 0;
  padding:0;
  font-family: sans-serif;
}
html {
  /* background-color: #9EC4F8; */
  /* background-color: #b2deea88; */
  /* background-color: #243b81; */
  background-color: #23518D;
}
