.container {
  margin: 4rem 4rem 4rem 4rem;
}

.gridContainer {
  margin: 0;
}
.logoContainer {
  position: relative;
}

.button {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2%;
  left: 16%;
  max-width: fit-content;
}

.buttonLink,
.buttonLink:link,
.buttonLink:visited,
.buttonLink:active {
  border-color: #23518d;
  border-width: 0.3rem;
  background-color: #f2b922;
  /* background-color:#FFFD8D; */
  padding: 1rem 2rem;
  text-decoration: none;
  border-style: solid;
}
.buttonLink:hover {
  background-color: #fadb83;
}

.buttonText {
  font-size: 3.5rem;
  font-weight: 600;
  color: #23518d;
}

.videoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
.video {
  width: 100%;
  height: 100%;
}

.logo {
  width: 70%;
  margin-bottom: 1rem;
}

.info {
  display: inline-block;
  margin: 3rem 3rem 1rem 3rem;
  text-align: center;
}
.mission {
  display: inline-block;
  margin: 3rem 3rem 1rem 3rem;
  text-align: center;
}
.missionText {
  font-size: 2.2rem;
  font-weight: 800;
  font-style: italic;
  color: #f2b922;
  /* color: #23518d; */
}

.infoText {
  margin: 0 0 3rem 0;
  font-size: 2rem;
  color: #ffffff;
  line-height: 3.5rem;
  font-weight: 400;
}

.socialMedia {
  margin: 0 0 2rem 0;
}

.download {
  background-color: #f2b922;
  /* background-color:#FFFD8D; */
  display: flex;
  margin: 1rem auto 3rem auto;
  width: 60%;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  border-style: double;
  border-width: 0.5rem;
  border-color: #23518d;
}

.downloadHeader {
  margin: 1rem 0;
  font-size: 3rem;
  font-weight: 600;
  color: #23518d;
}

.downloadText {
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 400;
  color: #23518d;
}

.socialMediaIcon,
.socialMediaIcon:link,
.socialMediaIcon:active,
.socialMediaIcon.visited {
  color: white;
}

.socialMediaIcon:hover {
  color: #aaccf8;
}

.ios,
.ios:link,
.ios:active,
.ios:visited,
.android,
.android:link,
.android:active,
.android:visited {
  color: #23518d;
}

.ios:hover,
.android:hover {
  color: #5177aa;
}

/**************************/
/* BELOW 120em             */
/**************************/
@media (max-width: 120em) {
  .buttonText {
    font-size: 3.2rem;
  }
}

/**************************/
/* BELOW 110em             */
/**************************/
@media (max-width: 110em) {
  .buttonText {
    font-size: 3rem;
  }
}

/**************************/
/* BELOW 95em             */
/**************************/
@media (max-width: 95em) {
  .buttonLink,
  .buttonLink:link,
  .buttonLink:visited,
  .buttonLink:active {
    border-color: #23518d;
    border-width: 0.3rem;
    background-color: #f2b922;
    /* background-color:#FFFD8D; */
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-style: solid;
  }

  .buttonText {
    font-size: 2.5rem;
  }
}

/**************************/
/* BELOW 80em             */
/**************************/
@media (max-width: 80em) {
  /* .gridContainer {
        margin:0;
        display:grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr 1.6fr;
    
    }


    .socialMedia {
        margin:0 0 2rem 0;
    } */

  .mission {
    margin: 2rem 1rem 2rem 1rem;
  }

  .missionText {
    font-size: 2rem;
    line-height: 3rem;
  }

  .info {
    margin: 0 0 1rem 0;
  }
  .infoText {
    font-size: 1.5rem;
    line-height: 3rem;
    font-weight: 400;
  }
  .logo {
    width: 85%;
    margin-bottom: 0;
  }

  .button {
    left: 9%;
  }

  .buttonText {
    font-size: 2rem;
  }
}

/* ************************
/* BELOW 75em             */
/**************************/

@media (max-width: 75em) {
  .logo {
    margin-bottom: 1rem;
    width: 90%;
  }
  .mission {
    margin: 1rem 1rem 1rem 1rem;
  }

  .button {
    left: 6.5%;
  }

  .missionText {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }

  .info {
    margin: 1rem 1rem 1rem 1rem;
    text-align: center;
  }

  .infoText {
    padding-top: 0;
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 400;
  }

  .download {
    display: flex;
    margin: 0 auto 2rem auto;
    width: 80%;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-style: double;
    border-width: 0.5rem;
  }

  .downloadHeader {
    margin: 1rem 0;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .downloadText {
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
  }
}

/**************************/
/* BELOW 65em             */
/**************************/

@media (max-width: 65em) {
  .container {
    margin: 1rem 1rem 1rem 1rem;
  }

  .buttonText {
    font-size: 1.5rem;
  }
  .missionText {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .buttonLink,
  .buttonLink:link,
  .buttonLink:visited,
  .buttonLink:active {
    border-color: #23518d;
    border-width: 0.3rem;
    background-color: #f2b922;
    /* background-color:#FFFD8D; */
    padding: 0.2rem 0.4rem;
    text-decoration: none;
    border-style: solid;
  }
}

/**************************/
/* BELOW 40em             */
/**************************/

@media (max-width: 40em) {
  .container {
    margin: 1rem 1rem 1rem 1rem;
  }

  .buttonText {
    font-size: 1rem;
  }

  .buttonLink,
  .buttonLink:link,
  .buttonLink:visited,
  .buttonLink:active {
    border-color: #23518d;
    border-width: 0.3rem;
    background-color: #f2b922;
    /* background-color:#FFFD8D; */
    padding: 0.1rem 0.2rem;
    text-decoration: none;
    border-style: solid;
  }
}

/**************************/
/* BELOW 30em             */
/**************************/

@media (max-width: 30em) {
  .container {
    margin: 1rem 1rem 1rem 1rem;
  }

  .buttonText {
    font-size: 1rem;
  }

  .buttonLink,
  .buttonLink:link,
  .buttonLink:visited,
  .buttonLink:active {
    border-color: #23518d;
    border-width: 0.2rem;
    background-color: #f2b922;
    /* background-color:#FFFD8D; */
    padding: 0;
    text-decoration: none;
    border-style: solid;
  }
}
