
.container{
    display:flex;
    margin: 1rem 8rem;
    flex:1;
    flex-direction: column;
  }

.infoContainer{
    margin: 2rem 1rem;
}

.header{
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 600;
    color:#f2b922;
  }

.info {
    text-align: left;
    margin: 1.5rem 0;
    line-height: 3rem;
    font-size: 1.7rem;
    font-weight: 400;
    color:#ffffff;
  }
  .linkText {
    word-break: break-all;
    line-height: 3rem;
    font-size: 1.7rem;
    font-weight: 400;
    /* color:#FFFD8D; */
    color:#f2b922;
  }
  .link{
    
    text-decoration: none;
  }

  .subHeader {
    text-align: left;
    margin: 3rem 0 1rem 0;
    line-height: 3rem;
    font-size: 2rem;
    font-weight:600;
    /* color:#FFFD8D; */
    color:#f2b922;
  }
  .logo {
    margin: 1rem 0;
    width:10%;
}

/**************************/
/* BELOW 140em             */
/**************************/

@media (max-width: 140em) {
  .logo {
    margin: 0;
    width:14%;
  }
}


/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .container{
      margin:1rem 0;
  }

  .header {
      margin:1rem 1rem;
      font-size:2rem;
  }

  .info{
      margin: 1.5rem 1rem;
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
    .logo {
      margin: 1rem 0;
      width:18%;
    }
    .infoContainer{
      margin: 0 1rem 1rem 1rem;
    }
    .linkText{
      line-height: 2.6rem;
      font-size: 1.5rem;
    }
    .subHeader {
      font-size: 1.7rem;
      line-height: 1.4;
      margin: 3rem 1rem 0 1rem;
    }
}


/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 60em) {
  .logo {
    margin: 1rem 0;
    width:24%;
  }

  .header{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8;
  }

}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 50em) {

  .header{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    /* color:#FFFD8D; */
    color:#f2b922;
  }


  .logo {
    margin: 1rem 0;
    width:30%;
  }
}